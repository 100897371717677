import React, { useState } from 'react';
import Slider from '@material-ui/core/Slider';
import Switch from '@material-ui/core/Switch';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import './ResultsList.css';
import {Link} from 'react-router-dom';
import {AnnotationsData, PubmedContent, Reference, ResultsData, TrialsContent} from '../LiteratureSearch/LiteratureSearch';
import {useAtom} from "jotai";
import {currentUserAtom} from "../../state/State";
import {RelevanceSwitches} from "./RelevanceSwitches";
import {ResultReferences} from "./ResultReferences";
import "./ResultReferences.css";
import { IconButton } from '@mui/material';
import { ExpandLess } from '@material-ui/icons';
import { ReactComponent as AusSvg } from '../../svg/AustralianFlag.svg';

const SUMMARY_TEXT_LENGTH = 1000;

interface IState {
    abstractExpanded: boolean[]
}

interface IProps {
    data: ResultsData<PubmedContent | TrialsContent>[],
    saveData: Function,
    relevanceUpdate: Function
    displayRelevanceButtons: boolean
    documentViewed: Function
}

const ResultsList: React.FC<IProps> = ({data, saveData, relevanceUpdate, displayRelevanceButtons, documentViewed}) => {
    const [abstractExpanded, setAbstractExpanded] = useState<IState['abstractExpanded']>(data.map(() => false) );

    const handleSwitchChange = (docId: string, rank: number, score: number, type: string, saved: boolean) => {
        saveData(docId, rank, score, type, saved);
    }

    const handleRelevanceUpdate = (index: number) => {
        relevanceUpdate(index);
    }

    const handleViewDocument = (NCTId: string, rank: number, type: string) => {
        documentViewed(NCTId, rank, type);
    }

    const toggleAbstractExpand = (index: number) => {
        const updated = structuredClone(abstractExpanded);
        updated[index] = !updated[index];
        setAbstractExpanded(updated);
    }

    return (
        <React.Fragment>
            {data && (
                <div className={"results-div"}>
                    {data.map((element: any, index: number) => {
                        if (element.type === 'trial') {
                            const {content, saved, type, rank, score} = element;
                            const {BriefTitle, NCTId, BriefSummary, Phase, annotations, ageEligibility, results_references, SubmitYear, Countries} = content;
                            return <TrialResult
                                key={index}
                                element={element}
                                index={index}
                                handleSwitchChange={() => handleSwitchChange(NCTId, rank, score, type, !saved)}
                                title={BriefTitle}
                                trialId={NCTId}
                                summary={BriefSummary}
                                annotations={annotations}
                                eligibility={ageEligibility}
                                references={results_references}
                                submitYear={SubmitYear}
                                countries={Countries}
                                phase={Phase}
                                saved={saved}
                                handleRelevanceUpdate={handleRelevanceUpdate}
                                displayRelevanceButtons={displayRelevanceButtons}
                                handleViewDocument={() => handleViewDocument(NCTId, rank, type)}
                                abstractExpanded={abstractExpanded[index]}
                                toggleAbstractExpand={toggleAbstractExpand}
                            />;
                        } else if (element.type === 'pubmed') {
                            const {content, saved, type, rank, score} = element;
                            const {title, journal, pubdate, pubmed_id, abstract, annotations, ageEligibility, publicationTypes} = content;
                            return <PubMedResult
                                key={index}
                                element={element}
                                index={index}
                                title={title}
                                journal={journal}
                                pubdate={pubdate}
                                pubmedId={pubmed_id}
                                summary={abstract}
                                annotations={annotations}
                                eligibility={ageEligibility}
                                publicationTypes={publicationTypes}
                                saved={saved}
                                handleSwitchChange={() => handleSwitchChange(pubmed_id, rank, score, type, !saved)}
                                handleRelevanceUpdate={() => handleRelevanceUpdate(index)}
                                displayRelevanceButtons={displayRelevanceButtons}
                                handleViewDocument={() => handleViewDocument(pubmed_id, rank, type)}
                                abstractExpanded={abstractExpanded[index]}
                                toggleAbstractExpand={toggleAbstractExpand}
                            />;
                        } else {
                            return null;
                        }
                    })}
                </div>
            )}
        </React.Fragment>
    );
};

export default ResultsList;
const TrialResult = (props: {
    index: number,
    element: any,
    handleSwitchChange: Function,
    title: string,
    trialId: Array<string>,
    summary: string,
    annotations: AnnotationsData,
    eligibility: Array<number>,
    references: Array<Reference>,
    submitYear: string,
    countries: Array<string>,
    phase: Array<string>
    saved: boolean,
    handleRelevanceUpdate: Function
    displayRelevanceButtons: boolean
    handleViewDocument: Function
    abstractExpanded: boolean
    toggleAbstractExpand: Function
}) => {
    const {
        index,
        element,
        handleSwitchChange,
        title,
        trialId,
        summary,
        annotations,
        eligibility,
        references,
        submitYear,
        countries,
        phase,
        saved,
        handleRelevanceUpdate,
        displayRelevanceButtons,
        handleViewDocument,
        abstractExpanded,
        toggleAbstractExpand
    } = props;
    const [currentUser,] = useAtom(currentUserAtom);

    const documentOnClick = (event: any) => {
        event.preventDefault();
        handleViewDocument();
        window.open(`https://beta.clinicaltrials.gov/study/${trialId}`);
    }

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);
    const anchorRef = React.useRef(null);
    const handleClick = () => {
        setAnchorEl(anchorRef.current);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className="results">
            <div className="trial-header">
                <div className="trial-type-title">
                    <label className="document-type result-type trial-type">Trial</label>
                    <div className='result-title title-references'>
                        {references && references.length > 0 && 
                            <ResultReferences
                                references={references}
                                anchorRef={anchorRef}
                                open={open}
                                handleClick={handleClick}
                                handleClose={handleClose}
                            ></ResultReferences>}
                        <Link className="title-title" to="route" target="_blank"
                            onClick={(event) => {
                                documentOnClick(event);
                        }}
                        >{title}</Link>
                        {/* <h4 className="result-title">{title}</h4> */}
                        <div className='submit-year'>
                            <label>{submitYear}</label>
                        </div>
                    </div>
                </div>
                <div className="trial-switch">
                    <RelevanceSwitches index={index}
                                       docObject={element}
                                       displayRelevanceButtons={displayRelevanceButtons}
                                       relevanceUpdate={() => handleRelevanceUpdate(index)}/>
                    <div style={{display: currentUser.current_case_title !== 'Explore' ? 'block' : 'none'}}>
                        <Switch
                            checked={!!saved}
                            onChange={() => handleSwitchChange()}
                            color="primary"
                            name="checkedB"
                            inputProps={{'aria-label': 'primary checkbox'}}
                        />
                    </div>
                </div>
            </div>
            <div className="result-abstract">
            <p>{summary && abstractExpanded ? summary : getSummaryText(summary)}
                    {isSummaryCondensable(summary) && <IconButton onClick={() =>toggleAbstractExpand(index)}>
                        {abstractExpanded ? <ExpandLess/> : <MoreHorizIcon/>}
                    </IconButton>}
                </p>
            </div>
            <div className="result-annot-age-eligib">
                <div className="result-annotations">
                    {annotations && getAnnotationLabels(annotations)}
                </div>
                <div className="result-age-eligib">
                    <Slider
                        id="sliderAge"
                        value={eligibility}
                        aria-labelledby="age slider"
                        valueLabelDisplay="off"
                        step={1}
                        marks={ageMarks}
                        max={2}
                        onChange={() => {
                        }}
                    />
                </div>
            </div>
            {phase && phase.length > 0 && <div className="phase">
                <div className="phase-labels" style={{ display: 'inline-flex'}}>
                    {getPhaseLabels(phase)}
                    <div className='aus-svg'>
                        {countries.includes('Australia') && <AusSvg/>}
                    </div>
                </div>
            </div>}
        </div>
    );
}

const isSummaryCondensable = (abstract: string) => {
    return abstract.length > SUMMARY_TEXT_LENGTH;
}

const getSummaryText = (abstract: string) => {
    return abstract.substring(0, SUMMARY_TEXT_LENGTH);
}

const getPhaseLabels = (phases: Array<string>): JSX.Element[] => {
    return phases.map((el: string) => <label key={`${el}`}>{el}</label>);
}

const getAnnotationLabels = (
    annotations: AnnotationsData
): JSX.Element[] => {
    let labels: JSX.Element[] = [];
    let annot: AnnotationsData = Object.keys(annotations)
        .filter((key: string) => key === 'disease' || key === 'drug' || key === 'gene' || key === 'mutation' || key === 'species')
        .reduce((obj, key) => {
            return {...obj, [key]: annotations[key]}
        }, {});
    Object.keys(annot).forEach((aKey: string) => {
        Object.keys(annot[aKey]).forEach((bKey) => {
            let annotCount = annot[aKey][bKey];
            let label: JSX.Element = <label
                className={`annotation-label ${aKey}-colour`}
                key={`${aKey} ${bKey} (${annotCount})`}
            >
                {`${bKey} ${annotCount >= 0 ? '(' + annotCount + ')' : ''}`}
            </label>;
            labels.push(label);
        });
    });
    return labels;
}

const PubMedResult = (props: {
    index: number,
    element: any,
    title: string,
    journal: string,
    pubdate: string,
    pubmedId: string,
    summary: string,
    annotations: AnnotationsData,
    eligibility: Array<number>,
    publicationTypes: Array<string>,
    saved: boolean,
    handleSwitchChange: Function
    handleRelevanceUpdate: Function
    displayRelevanceButtons: boolean
    handleViewDocument: Function
    abstractExpanded: boolean
    toggleAbstractExpand: Function
}) => {
    const {
        index,
        element,
        title,
        journal,
        pubdate,
        pubmedId,
        summary,
        annotations,
        eligibility,
        publicationTypes,
        saved,
        handleSwitchChange,
        handleRelevanceUpdate,
        displayRelevanceButtons,
        handleViewDocument,
        abstractExpanded,
        toggleAbstractExpand
    } = props;
    const [currentUser,] = useAtom(currentUserAtom);

    const documentOnClick = (event: any) => {
        event.preventDefault();
        handleViewDocument();
        if (pubmedId != null && pubmedId.toUpperCase().startsWith("10.1200/JCO.")) {
            // Some IDs contain a backslash before an underscore. Remove the backslash.
            // These backslashes have come from the original bibtex files.
            let pubmedIdModified = pubmedId.replace("\\_", "_");
            window.open(`https://ascopubs.org/doi/abs/${pubmedIdModified}`);
        } else {
            window.open(`https://pubmed.ncbi.nlm.nih.gov/${pubmedId}`);
        }
    }

    const pubYear = () => {
        let publicationDate = new Date(pubdate);
        return publicationDate.getFullYear();
    }

    return (
        <React.Fragment>
        <div className="results">
            <div className="pubmed-header">
                <div className="pubmed-type-title">
                    <label className="result-type pubmed-type">Article</label>
                    <div className='result-title title-references'>
                        <Link className="title-title" to="route" target="_blank"
                              onClick={(event) => {
                                  documentOnClick(event);
                              }}
                        >{title}</Link>
                        <div className='pubmed-journal'>
                            <label>{pubYear()} - {journal}</label>
                        </div>
                    </div>
                </div>
                <div className="pubmed-switch">
                    <RelevanceSwitches index={index}
                                       docObject={element}
                                       displayRelevanceButtons={displayRelevanceButtons}
                                       relevanceUpdate={() => handleRelevanceUpdate(index)}/>

                    <div style={{display: currentUser.current_case_title !== 'Explore' ? 'block' : 'none'}}>
                        <Switch
                            checked={!!saved}
                            onChange={() => handleSwitchChange()}
                            color="primary"
                            name="checkedB"
                            inputProps={{'aria-label': 'primary checkbox'}}
                        />
                    </div>
                </div>
            </div>
            <div className='result-abstract'> 
                <p>{summary && abstractExpanded ? summary : getSummaryText(summary)}
                    {isSummaryCondensable(summary) && <IconButton onClick={() => toggleAbstractExpand(index)} className='abstract-expand-btn'>
                        {abstractExpanded ? <ExpandLess/> : <MoreHorizIcon/>}
                    </IconButton>}
                </p>
            </div>
            <div className="result-annot-age-eligib">
                <div className="result-annotations">
                    {annotations && getAnnotationLabels(annotations)}
                </div>
                <div className="result-age-eligib">
                    <Slider
                        id="sliderAge"
                        value={eligibility}
                        aria-labelledby="age slider"
                        valueLabelDisplay="off"
                        step={1}
                        marks={ageMarks}
                        max={2}
                        onChange={() => {
                        }}
                    />
                </div>
            </div>
            <div className="phase">
                <div className="phase-labels">
                    <label>Preclinical</label>
                    {publicationTypes.map((pubType: string) => <label className='publication-type' key={pubType}>{pubType}</label>)}
                </div>
            </div>
        </div>
        </React.Fragment>
    );
}

export const ageMarks: Array<{ value: number, label: string }> = [
    {
        value: 0,
        label: '<12',
    },
    {
        value: 1,
        label: '12-Adult',
    },
    {
        value: 2,
        label: 'Adult',
    }
];

